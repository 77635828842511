(function(module) {
try {
  module = angular.module('turm-templates-homepage');
} catch (e) {
  module = angular.module('turm-templates-homepage', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/homepage/apply.tpl.html',
    '<style>\n' +
    '    .apply-head{\n' +
    '        font-weight: 700;\n' +
    '        font-size: 3em;\n' +
    '    }\n' +
    '\n' +
    '    input{\n' +
    '        border-style:solid;\n' +
    '        border-color:#000000;\n' +
    '        border-width: 0;\n' +
    '        background-color: darkgrey;\n' +
    '        height: 2.5em;\n' +
    '        margin-bottom: 1.5em;\n' +
    '        font-size: 1.25em;\n' +
    '        font-weight: 600;\n' +
    '    }\n' +
    '    textarea{\n' +
    '        border-style:solid;\n' +
    '        border-color:#000000;\n' +
    '        border-width: 0;\n' +
    '        background-color: darkgrey;\n' +
    '        margin-bottom: 1.5em;\n' +
    '        font-size: 1.25em;\n' +
    '        font-weight: 600;\n' +
    '    }\n' +
    '    select{\n' +
    '        height: 2.5em;\n' +
    '        font-size: 1.25em;\n' +
    '        font-weight: 600;\n' +
    '        background-color: darkgrey;\n' +
    '    }\n' +
    '    .swcap>.sweetcaptcha{\n' +
    '        max-width: 100%!important;\n' +
    '    }\n' +
    '\n' +
    '    .apply-placeholder{\n' +
    '        flex: 0 0 12.5%;\n' +
    '        -webkit-flex: 0 0 12.5%;\n' +
    '    }\n' +
    '    .apply-header{\n' +
    '        width: 100%;\n' +
    '        margin-bottom: 5em;\n' +
    '    }\n' +
    '    .apply-form>div{\n' +
    '        margin: 0 3em 0 0;\n' +
    '    }\n' +
    '    .absolute-center{\n' +
    '        position: absolute;\n' +
    '        top:0;\n' +
    '        bottom: 0;\n' +
    '        left: 0;\n' +
    '        right: 0;\n' +
    '        height: 100%;\n' +
    '    }\n' +
    '    .dropYourImage{\n' +
    '        width: 100%;\n' +
    '        height: 100%;\n' +
    '        position: absolute;\n' +
    '    }\n' +
    '\n' +
    '    #image-preview {\n' +
    '        width: 400px;\n' +
    '        height: 400px;\n' +
    '        position: relative;\n' +
    '        overflow: hidden;\n' +
    '        background-color: #ffffff;\n' +
    '        color: #ecf0f1;}\n' +
    '    #image-preview input {\n' +
    '        line-height: 200px;\n' +
    '        font-size: 200px;\n' +
    '        position: absolute;\n' +
    '        opacity: 0;\n' +
    '        z-index: 10;\n' +
    '    }\n' +
    '    #image-preview label {\n' +
    '        position: absolute;\n' +
    '        z-index: 5;\n' +
    '        opacity: 0.8;\n' +
    '        cursor: pointer;\n' +
    '        background-color: #bdc3c7;\n' +
    '        width: 200px;\n' +
    '        height: 50px;\n' +
    '        font-size: 20px;\n' +
    '        line-height: 50px;\n' +
    '        text-transform: uppercase;\n' +
    '        top: 0;\n' +
    '        left: 0;\n' +
    '        right: 0;\n' +
    '        bottom: 0;\n' +
    '        margin: auto;\n' +
    '        text-align: center;\n' +
    '    }\n' +
    '\n' +
    '\n' +
    '</style>\n' +
    '\n' +
    '<div class="flex-col apply-container" ng-init="init()">\n' +
    '    <div class="rent-placeholder-half"></div>\n' +
    '    <div class="flex-col flex apply-content">\n' +
    '        <div class="flex-col apply-header">\n' +
    '            <div class="apply-head">BEWERBEN</div>\n' +
    '            <div style="font-weight: 300; font-size: 1.5em;">WIR BRAUCHEN DICH IM TURM!</div>\n' +
    '        </div>\n' +
    '        <form class="apply-row flex apply-form">\n' +
    '            <div class="flex-col flex">\n' +
    '                <div class="flex-col flex" style="margin-bottom: 1em;">\n' +
    '                    <div class="flex-s-auto" style="font-weight: bold;margin-bottom: 5px; font-size: 1.25em; font-weight: 300;">FOTO</div>\n' +
    '                    <div class="flex" style="background-color: black; text-align: center; color: white; position: relative;">\n' +
    '                        <!--<div class="dropYourImage">\n' +
    '                        </div>-->\n' +
    '                        <div id="image-preview" style="height: 100%; width: 100%; position: absolute; background-color: transparent; min-height: 2em;">\n' +
    '                            <label for="image-upload" id="image-label">Choose File</label>\n' +
    '                            <input type="file" name="image" id="image-upload" />\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <label for="applyFirstname" style="width: 100%;">VORNAME</label>\n' +
    '                    <input type="text" id="applyFirstname" name="applyFirstname" style="width: 100%; min-height: 20px;" ng-model="apply.firstname">\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <label for="applyLastname" style="width: 100%;">NACHNAME</label>\n' +
    '                    <input type="text" id="applyLastname" name="applyLastname" style="width: 100%;" ng-model="apply.lastname">\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="flex-col flex">\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <div class="apply-row flex">\n' +
    '                        <div class="flex-col flex" style="margin-right: 0.5em;">\n' +
    '                            <label for="applyEmail">EMAIL</label>\n' +
    '                            <input type="text" id="applyEmail" name="applyEmail" ng-model="apply.mail">\n' +
    '                        </div>\n' +
    '                        <div class="flex-col flex" style="margin-left: 0.5em;">\n' +
    '                            <label for="applyPhone">TELEFON</label>\n' +
    '                            <input type="text" id="applyPhone" name="applyPhone" ng-model="apply.tel">\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto" style="margin-bottom: 1.5em;">\n' +
    '                    <label for="applyStatus">STATUS</label>\n' +
    '                    <select class="vam-dropdown" id="applyStatus" name="applyStatus" ng-model="apply.status">\n' +
    '                        <option>Student</option>\n' +
    '                        <option>Azubi</option>\n' +
    '                        <option>Anderes</option>\n' +
    '                    </select>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="applyExperience">GASTRO-ERFAHRUNG</label>\n' +
    '                    <textarea type="text" id="applyExperience" name="applyExperience" style="flex:1; resize: none;" ng-model="apply.exp"></textarea>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="flex-col flex">\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <div class="apply-row flex">\n' +
    '                        <div class="flex-col flex" style="width: 100%;">\n' +
    '                            <label for="applyBirthday">GEBURTSDATUM</label>\n' +
    '                            <input type="text" id="applyBirthday" name="applyBirthday" ng-model="apply.geb">\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="applyNotes" style="width: 100%;">BEMERKUNG</label>\n' +
    '                    <textarea type="text" id="applyNotes" name="applyNotes" style="flex:1; resize: none;" ng-model="apply.note"></textarea>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <script src="https://www.google.com/recaptcha/api.js" async defer></script>\n' +
    '                    <div class="g-recaptcha" data-sitekey="6LeHORoTAAAAAOOmxv5qR5NQuv41AnuOEqvmt8Fj"></div>\n' +
    '                    <button type="button" class="vam-button btn-block" style="text-align: left;margin-bottom: 2em;" ng-click="sendApplication()">Abschicken</button>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto" ng-show="apply.error">\n' +
    '                    <span>{{apply.errorMessage}}</span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '    <div class="apply-placeholder"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('turm-templates-homepage');
} catch (e) {
  module = angular.module('turm-templates-homepage', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/homepage/contentList.tpl.html',
    '<div ng-if="!phone && !portrait" style="height: 100%; position: relative;">\n' +
    '    <div class="scroll-outer-container-cards flex-row" ng-scrollbars ng-scrollbars-config="config">\n' +
    '        <div class="scroll-inner-container-cards" style="display: flex; flex-direction: row; display: -webkit-flex; -webkit-flex-direction: row;">\n' +
    '            <style>\n' +
    '                .max-half{\n' +
    '                    max-width: 50%;\n' +
    '                }\n' +
    '                .max-full{\n' +
    '                    max-width: 100%;\n' +
    '                }\n' +
    '            </style>\n' +
    '            <style>\n' +
    '                .full-image img{\n' +
    '                    height: 100%;\n' +
    '                }\n' +
    '                .full-with-extra-image img{\n' +
    '                    height: calc(100% - 6em);\n' +
    '                    /*height: 100%;*/\n' +
    '                }\n' +
    '                .extra-image img{\n' +
    '                    width: auto;\n' +
    '                    height: 5em;\n' +
    '                    margin-top: .5em;\n' +
    '                    margin-right: .5em;\n' +
    '                    position: relative;\n' +
    '                    z-index: 11;\n' +
    '                }\n' +
    '            </style>\n' +
    '\n' +
    '            <div class="card" ng-repeat="event in visibleEvents" card-id="{{event.id}}"\n' +
    '                 ng-class="{\'middle-card\':event.id == activeCardId, \'outer-card\': event.id != activeCardId}"\n' +
    '                 style="width: 60vw; float: left; height: 100%;">\n' +
    '                <div class="card-content" itemscope itemtype="http://schema.org/Event">\n' +
    '                    <div style="display: flex; flex-direction: row; display: -webkit-flex; -webkit-flex-direction: row; height: 100%;">\n' +
    '                        <div style="width: auto; flex: auto; -webkit-flex: auto; direction: rtl;"\n' +
    '                             class="card-content-left" ng-class="{\'active-card\': event.active, \'max-half\': event.eventImages.length > 1, \'max-full\': event.eventImages.length == 0}">\n' +
    '                            <div class="content-scroller">\n' +
    '                                <div style="height: auto; overflow: auto; direction: ltr;">\n' +
    '                                    <div style="margin: 1em 0 1em 0; direction: ltr;">\n' +
    '                                        <p class="event-text-title" itemprop="name">{{::event.eventTitle}}</p>\n' +
    '                                        <p class="event-text-date" itemprop="startDate" content="{{::event.eventDate | date:\'yyyy-MM-ddT\'}}{{::event.eventEntryTime | date:\'HH:mm\' : \'UTC\'}}">{{::event.eventDate | date:\'EEEE d. MMM yyyy\'}}</p>\n' +
    '                                        <meta itemprop="url" content="http://turm.club/">\n' +
    '                                        <span itemprop="location" itemscope itemtype="http://schema.org/EventVenue">\n' +
    '                                            <meta itemprop="name" content="Turm">\n' +
    '                                            <meta itemprop="address" content="Friedemann-Bach-Platz 5, 06108 Halle (Saale)">\n' +
    '                                        </span>\n' +
    '                                    </div>\n' +
    '                                    <div style="height: auto; overflow: auto; direction: ltr;">\n' +
    '                                        <div class="card-content-padding" style="overflow-y: auto;">\n' +
    '                                            <div>\n' +
    '                                                <div class="event-text-description" itemprop="description" my-html="::event.eventDescription">\n' +
    '                                                </div>\n' +
    '                                            </div>\n' +
    '                                            <div ng-if="::event.eventNote">\n' +
    '                                                <span class="event-text-note">\n' +
    '                                                    {{::event.eventNote}}\n' +
    '                                                </span>\n' +
    '                                            </div>\n' +
    '                                            <p style="font-size: 1.5em;" ng-if="event.fbEvent !== \'\' && event.fbEvent">\n' +
    '                                                <a href="https://www.facebook.com/events/{{event.fbEvent}}/" target="_blank">Facebook-Event</a>\n' +
    '                                            </p>\n' +
    '\n' +
    '                                            <div ng-if="::(event.eventEntryTime || event.eventStartTime)">\n' +
    '                                    <span class="event-text-infos" ng-if="::event.eventEntryTime">\n' +
    '                                        EINLASSZEIT: {{::event.eventEntryTime | date:\'HH:mm\' }}</span>\n' +
    '\n' +
    '                                    <span class="event-text-infos" ng-if="::(event.eventEntryTime && event.eventStartTime)">\n' +
    '                                        &nbsp|&nbsp\n' +
    '                                    </span>\n' +
    '                                    <span class="event-text-infos" ng-if="::event.eventStartTime"  property="doorTime" content="{{::event.eventDate | date:\'yyyy-MM-ddT\'}}{{::event.eventStartTime | date:\'HH:mm\' : \'UTC\'}}">\n' +
    '                                        STARTZEIT: {{::event.eventStartTime | date:\'HH:mm\'}}\n' +
    '                                    </span>\n' +
    '                                            </div>\n' +
    '                                            <div ng-if="::(event.eventEntryFeeFull || event.eventEntryFeeLess)" itemprop="offers" itemscope itemtype="http://schema.org/Offer">\n' +
    '                                                <meta itemprop="price" price="{{::event.eventEntryFeeFull}}"/>\n' +
    '                                                <meta itemprop="priceCurrency" content="EUR" />\n' +
    '                                                <meta itemprop="url" content="http://turm.club/">\n' +
    '\n' +
    '                                                <span class="event-text-infos" ng-if="::event.eventEntryFeeFull">\n' +
    '                                                    EINTRITT: {{::event.eventEntryFeeFull}}</span>\n' +
    '                                                <span class="event-text-infos" ng-if="::(event.eventEntryFeeFull && event.eventEntryFeeLess)">\n' +
    '                                                    &nbsp|&nbsp\n' +
    '                                                </span>\n' +
    '                                                <span class="event-text-infos" ng-if="::event.eventEntryFeeLess">\n' +
    '                                                    ERMÄßIGT: {{::event.eventEntryFeeLess}}\n' +
    '                                                </span>\n' +
    '                                            </div>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                    <div class="shadow" ng-if="false" ng-class="{\'shadow-white\': event.active, \'shadow-black\': !event.active}"></div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div style="width: auto; max-width: 50%; min-width: 25%; position: relative; overflow: hidden; text-align: right; flex: 1 0 auto; -webkit-flex: 1 0 auto;"\n' +
    '                             class="card-content-right" >\n' +
    '                            <a href="{{eventImage.src}}" data-lightbox="lb[{{event.id}}]" ng-repeat="eventImage in event.eventImages | filter:{ type: \'first\' }"\n' +
    '                               ng-class="{\'full-image\':$first && event.eventImages.length == 1,\n' +
    '                               \'full-with-extra-image\': $first && event.eventImages.length > 1}">\n' +
    '                                <img ng-src="{{eventImage.src}}" alt="{{eventImage.alt}}" ng-hide="$index > 0" itemprop="image">\n' +
    '                            </a>\n' +
    '                            <div style="height: 6em; width: auto; background-color: transparent; /* rgba(255,255,255,0.5); */\n' +
    '                                position:absolute; bottom: 0; right: 0;" ng-if="event.eventImages.length > 1">\n' +
    '                                <a href="{{eventImage.src}}" data-lightbox="la[{{event.id}}]" ng-repeat="eventImage in event.eventImages"\n' +
    '                                   class="extra-image">\n' +
    '                                    <img ng-src="{{eventImage.src}}" alt="{{eventImage.alt}}" ng-if="$index <= 5">\n' +
    '                                </a>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-if="phone && portrait" class="phone" style="height: 100%; position: relative;    z-index: 1001;">\n' +
    '    <div class="card" card-id="{{eventMobile.id}}"\n' +
    '         ng-class="{\'middle-card\': true}"\n' +
    '            >\n' +
    '        <div class="card-content" style="background-color: #1b1b1b; color: #fff;">\n' +
    '            <div style="display: flex; flex-direction: column; display: -webkit-flex; -webkit-flex-direction: column; height: 100%;">\n' +
    '                <div style="width: auto; flex: auto; -webkit-flex: auto"\n' +
    '                     ng-class="{\'active-card\': eventMobile.active, \'max-half\': eventMobile.eventImages.length > 1, \'max-full\': eventMobile.eventImages.length == 0}">\n' +
    '                    <div style="margin: 1em 0 1em 0;">\n' +
    '                        <p class="event-text-title">{{eventMobile.eventTitle}}</p>\n' +
    '                        <p class="event-text-date">{{eventMobile.eventDate | date:\'EEEE d. MMM yyyy\'}}</p>\n' +
    '                    </div>\n' +
    '                    <div style="height: auto; overflow: auto;">\n' +
    '                        <div class="card-content-padding" style="overflow-y: auto;">\n' +
    '                            <div>\n' +
    '                                    <div class="event-text-description" my-html="eventMobile.eventDescription">\n' +
    '                                    </div>\n' +
    '                            </div>\n' +
    '                            <div ng-if="event.eventNote">\n' +
    '                                    <span class="event-text-note">\n' +
    '                                        {{eventMobile.eventNote}}\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <p style="font-size: 1.5em;" ng-if="eventMobile.fbEvent !== \'\' && eventMobile.fbEvent">\n' +
    '                                <a href="https://www.facebook.com/events/{{eventMobile.fbEvent}}/" target="_blank">Facebook-Event</a>\n' +
    '                            </p>\n' +
    '\n' +
    '                            <div ng-if="(eventMobile.eventEntryTime || eventMobile.eventStartTime)">\n' +
    '                                    <span class="event-text-infos" ng-if="eventMobile.eventEntryTime">\n' +
    '                                        EINLASSZEIT: {{eventMobile.eventEntryTime | date:\'HH:mm\'}}</span>\n' +
    '                                    <span class="event-text-infos" ng-if="(eventMobile.eventEntryTime && eventMobile.eventStartTime)">\n' +
    '                                        &nbsp|&nbsp\n' +
    '                                    </span>\n' +
    '                                    <span class="event-text-infos" ng-if="eventMobile.eventStartTime">\n' +
    '                                        STARTZEIT: {{eventMobile.eventStartTime | date:\'HH:mm\'}}\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '                            <div ng-if="(eventMobile.eventEntryFeeFull || eventMobile.eventEntryFeeLess)">\n' +
    '                                    <span class="event-text-infos" ng-if="::eventMobile.eventEntryFeeFull">\n' +
    '                                        EINTRITT: {{eventMobile.eventEntryFeeFull}}</span>\n' +
    '                                    <span class="event-text-infos" ng-if="::(eventMobile.eventEntryFeeFull && eventMobile.eventEntryFeeLess)">\n' +
    '                                        &nbsp|&nbsp\n' +
    '                                    </span>\n' +
    '                                    <span class="event-text-infos" ng-if="::eventMobile.eventEntryFeeLess">\n' +
    '                                        ERMÄßIGT: {{eventMobile.eventEntryFeeLess}}\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="shadow" ng-if="false" ng-class="{\'shadow-white\': event.active, \'shadow-black\': !event.active}"></div>\n' +
    '                </div>\n' +
    '                <div style="width: auto; position: relative; overflow: hidden; text-align: right; flex: auto; -webkit-flex: auto;">\n' +
    '                    <a href="{{eventImage.src}}" data-lightbox="lb[{{eventMobile.id}}]" ng-repeat="eventImage in eventMobile.eventImages"\n' +
    '                       ng-class="{\'full-image\':$first && eventMobile.eventImages.length == 1,\n' +
    '                               \'full-with-extra-image\': $first && eventMobile.eventImages.length > 1}" >\n' +
    '                        <img style="width: 100%;" ng-src="{{eventImage.src}}" alt="{{eventImage.alt}}" ng-if="$first">\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-if="portrait && !phone" class="portrait" style="height: 100%; position: relative;    z-index: 1001;">\n' +
    '    <div class="card" card-id="{{eventMobile.id}}"\n' +
    '         ng-class="{\'middle-card\': true}">\n' +
    '        <div class="card-content" style="background-color: #1b1b1b; color: #fff;">\n' +
    '            <div style="display: flex; flex-direction: column; display: -webkit-flex; -webkit-flex-direction: column; height: 100%;">\n' +
    '                <div style="width: auto; flex: auto; -webkit-flex: auto"\n' +
    '                     ng-class="{\'active-card\': eventMobile.active, \'max-half\': eventMobile.eventImages.length > 1, \'max-full\': eventMobile.eventImages.length == 0}">\n' +
    '                    <div style="margin: 1em 0 1em 0;">\n' +
    '                        <p class="event-text-title">{{eventMobile.eventTitle}}</p>\n' +
    '                        <p class="event-text-date">{{eventMobile.eventDate | date:\'EEEE d. MMM yyyy\'}}</p>\n' +
    '                    </div>\n' +
    '                    <div style="height: auto; overflow: auto;">\n' +
    '                        <div class="card-content-padding" style="overflow-y: auto;">\n' +
    '                            <div>\n' +
    '                                    <span class="event-text-description">\n' +
    '                                        {{eventMobile.eventDescription}}\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '                            <div ng-if="event.eventNote">\n' +
    '                                    <span class="event-text-note">\n' +
    '                                        {{eventMobile.eventNote}}\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '                            <div ng-if="::(eventMobile.eventEntryTime || eventMobile.eventStartTime)">\n' +
    '                                    <span class="event-text-infos" ng-if="::eventMobile.eventEntryTime">\n' +
    '                                        EINLASSZEIT: {{eventMobile.eventEntryTime | date:\'HH:mm\'}}</span>\n' +
    '                                    <span class="event-text-infos" ng-if="::(eventMobile.eventEntryTime && eventMobile.eventStartTime)">\n' +
    '                                        &nbsp|&nbsp\n' +
    '                                    </span>\n' +
    '                                    <span class="event-text-infos" ng-if="::eventMobile.eventStartTime">\n' +
    '                                        STARTZEIT: {{eventMobile.eventStartTime | date:\'HH:mm\'}}\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <p style="font-size: 1.5em;" ng-if="eventMobile.fbEvent !== \'\' && eventMobile.fbEvent">\n' +
    '                                <a href="https://www.facebook.com/events/{{eventMobile.fbEvent}}/" target="_blank">Facebook-Event</a>\n' +
    '                            </p>\n' +
    '\n' +
    '                            <div ng-if="(eventMobile.eventEntryFeeFull || eventMobile.eventEntryFeeLess)">\n' +
    '                                    <span class="event-text-infos" ng-if="eventMobile.eventEntryFeeFull">\n' +
    '                                        EINTRITT: {{eventMobile.eventEntryFeeFull}}</span>\n' +
    '                                    <span class="event-text-infos" ng-if="(eventMobile.eventEntryFeeFull && eventMobile.eventEntryFeeLess)">\n' +
    '                                        &nbsp|&nbsp\n' +
    '                                    </span>\n' +
    '                                    <span class="event-text-infos" ng-if="eventMobile.eventEntryFeeLess">\n' +
    '                                        ERMÄßIGT: {{eventMobile.eventEntryFeeLess}}\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="shadow" ng-if="false" ng-class="{\'shadow-white\': event.active, \'shadow-black\': !event.active}"></div>\n' +
    '                </div>\n' +
    '                <div style="width: auto; position: relative; overflow: hidden; text-align: right; flex: auto; -webkit-flex: auto;">\n' +
    '                    <a href="{{eventImage.src}}" data-lightbox="lb[{{eventMobile.id}}]" ng-repeat="eventImage in eventMobile.eventImages"\n' +
    '                       ng-class="{\'full-image\':$first && eventMobile.eventImages.length == 1,\n' +
    '                               \'full-with-extra-image\': $first && eventMobile.eventImages.length > 1}" >\n' +
    '                        <img style="width: 100%;" ng-src="{{eventImage.src}}" alt="{{eventImage.alt}}" ng-if="$first">\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('turm-templates-homepage');
} catch (e) {
  module = angular.module('turm-templates-homepage', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/homepage/here.tpl.html',
    '<!--\n' +
    '<div class="here-container">\n' +
    '    <div class="here-row pad-down">\n' +
    '        <div class="here-cell here-cell-left upper" ng-mouseover="tanzsaal = true" ng-mouseleave="tanzsaal = false">\n' +
    '            <div class="schwarz" ng-class="{\'schwarz-out\': !tanzsaal, \'schwarz-in\': tanzsaal}"></div>\n' +
    '            <div class="here-cell-content">\n' +
    '                <p class="here-header" ng-class="{\'bg\': !tanzsaal, \'sm\': tanzsaal}">TANZSAAL</p>\n' +
    '                <br>\n' +
    '                <p class="here-text" ng-if="tanzsaal">Neben einer Bühne mit einer kleinen Bar aus&shyge&shystattet können in dem mit moderner Licht- und Sound&shytechnik aus&shygerüsteten Saal problemlos Live-Konzerte, Theater&shyvor&shyführungen oder Tanz&shyver&shyanstaltungen durch&shygeführt werden. Für größere Gesell&shyschaften kann auch eine komplette Belegung mit Stühlen und Tischen erfolgen.</p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="here-cell here-cell-rigth upper" ng-mouseover="rondell = true" ng-mouseleave="rondell = false">\n' +
    '            <div class="schwarz" ng-class="{\'schwarz-out\': !rondell, \'schwarz-in\': rondell}"></div>\n' +
    '            <div class="here-cell-content">\n' +
    '                <p class="here-header" ng-class="{\'bg\': !rondell, \'sm\': rondell}">RONDELL</p>\n' +
    '                <br>\n' +
    '                <p class="here-text" ng-if="rondell">Urig, gemüt&shylich und immer eine Attrak&shytion! Hier können Sie bei einem kühlen Bier und den Klängen einer Band oder eines DJ\'s aus&shygelassen bis in die frühen Morgen&shystunden feiern.</p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="here-row">\n' +
    '        <div class="here-cell here-cell-left lower" ng-mouseover="burggraben = true" ng-mouseleave="burggraben = false">\n' +
    '            <div class="schwarz" ng-class="{\'schwarz-out\': !burggraben, \'schwarz-in\': burggraben}"></div>\n' +
    '            <div class="here-cell-content">\n' +
    '                <p class="here-header" ng-class="{\'bg\': !burggraben, \'sm\': burggraben}">BURGGRABEN</p>\n' +
    '                <br>\n' +
    '                <p class="here-text" ng-if="burggraben">Der Graben der Moritz&shyburg ermög&shylicht zur warmen Jahres&shyzeit Bier&shygarten&shyatmos&shyphäre und Open-Air Konzerte im antiken Gemäuer und er&shylaubt die Errichtung von Bühnen und Fest&shyzelten.</p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="here-cell here-cell-rigth lower"ng-mouseover="weinabteil = true" ng-mouseleave="weinabteil = false">\n' +
    '            <div class="schwarz" ng-class="{\'schwarz-out\': !weinabteil, \'schwarz-in\': weinabteil}"></div>\n' +
    '            <div class="here-cell-content">\n' +
    '                <p class="here-header" ng-class="{\'bg\': !weinabteil, \'sm\': weinabteil}">WEINABTEIL</p>\n' +
    '                <br>\n' +
    '                <p class="here-text" ng-if="weinabteil">Der wohl beste Ort, um sich vom Tru&shybel der Ver&shyan&shystal&shytungen auf ein Gespräch zurück&shyzuziehen. Eine groß&shyzügige Bar ver&shysorgt die Gäste mit hei&shymischen Weinen und leckeren Cock&shytails.</p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>-->\n' +
    '<!--\n' +
    '<style>\n' +
    '    .grid-container{\n' +
    '        display: flex;}\n' +
    '    .grid-row{\n' +
    '        display: flex;\n' +
    '        flex-direction: row;\n' +
    '    }\n' +
    '    .grid-col{\n' +
    '        flex-direction: column;\n' +
    '        width: 45%;\n' +
    '        float:left;\n' +
    '        max-height: calc(100% - 60px);\n' +
    '    }\n' +
    '    .grid-container img{\n' +
    '        height: calc(50% - 60px); width: 100%; object-fit: cover;\n' +
    '    }\n' +
    '    article{\n' +
    '        overflow: hidden;}\n' +
    '</style>\n' +
    '<div class="grid-container">\n' +
    '    <div class="grid-row">\n' +
    '        <div class="grid-col">\n' +
    '            <img src="/images/Turm_Location_Nacht.jpg">\n' +
    '            <img src="/images/turm_rundgang_010.jpg">\n' +
    '        </div>\n' +
    '        <div class="grid-col">\n' +
    '            <img src="/images/turm_rundgang_009.jpg">\n' +
    '            <img src="/images/turm_rundgang_008.jpg">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '-->\n' +
    '<!--\n' +
    '<style>\n' +
    '    .grid-block a {\n' +
    '        font-size: 16px;\n' +
    '        overflow: hidden;\n' +
    '        display: inline-block;\n' +
    '        /* margin-bottom: 8px; */\n' +
    '        width: calc(50% - 4px);\n' +
    '        /* margin-right: 8px; */\n' +
    '    }\n' +
    '\n' +
    '    .grid-block a:nth-of-type(2n) {\n' +
    '        margin-right: 0;\n' +
    '    }\n' +
    '\n' +
    '    @media screen and (min-width: 50em) {\n' +
    '        .grid-block a {\n' +
    '            width: calc(33% - 6px);\n' +
    '        }\n' +
    '\n' +
    '        .grid-block a:nth-of-type(2n) {\n' +
    '            /* margin-right: 8px; */\n' +
    '        }\n' +
    '\n' +
    '        .grid-block a:nth-of-type(4n) {\n' +
    '            margin-right: 0;\n' +
    '        }\n' +
    '    }\n' +
    '\n' +
    '    .grid-block a:hover img {\n' +
    '        transform: scale(1.15);\n' +
    '    }\n' +
    '\n' +
    '    .grid-block figure {\n' +
    '        margin: 0;\n' +
    '    }\n' +
    '\n' +
    '    .grid-block img {\n' +
    '        border: none;\n' +
    '        max-width: 100%;\n' +
    '        height: auto;\n' +
    '        display: block;\n' +
    '        background: #ccc;\n' +
    '        transition: transform .2s ease-in-out;\n' +
    '    }\n' +
    '</style> -->\n' +
    '<style>\n' +
    '    .grid-block {\n' +
    '        display: flex;\n' +
    '        flex-wrap: wrap;\n' +
    '        justify-content: space-between; /* or space-around */\n' +
    '    }\n' +
    '\n' +
    '    .grid-block a {\n' +
    '        display: inline-block;\n' +
    '         margin-bottom: 4px;\n' +
    '        width: calc(33%);\n' +
    '    }\n' +
    '\n' +
    '    .grid-block img{\n' +
    '        max-width: 100%;\n' +
    '    }\n' +
    '\n' +
    '    @media screen and (max-width: 2000px) {\n' +
    '        .grid-block a {\n' +
    '            width: calc(50% - 4px);\n' +
    '        }\n' +
    '    }\n' +
    '\n' +
    '    @media screen and (max-width: 1024px){\n' +
    '        .grid-block a {\n' +
    '            width: calc(100%);\n' +
    '        }\n' +
    '    }\n' +
    '</style>\n' +
    '<div class="grid-block">\n' +
    '    <a href="/images/rundgang01.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang01.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang02.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang02.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang03.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang03.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang04.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang04.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang05.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang05.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang06.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang06.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang07.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang07.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang08.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang08.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang09.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang09.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang10.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang10.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang11.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang11.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang12.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang12.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang13.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang13.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang14.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang14.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang15.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang15.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang16.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang16.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang17.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang17.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang18.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang18.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang19.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang19.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '    <a href="/images/rundgang20.jpg" target="_blank">\n' +
    '        <figure>\n' +
    '            <img src="/images/rundgang20.jpg" alt="">\n' +
    '        </figure>\n' +
    '    </a>\n' +
    '</div>\n' +
    '\n' +
    '<!--\n' +
    '<div class="slippry">\n' +
    '    <ul class="slippry-container">\n' +
    '        <li data-thumb="/images/turm_rundgang_009.jpg">\n' +
    '            <img src="/images/turm_rundgang_009.jpg" />\n' +
    '        </li>\n' +
    '        <li data-thumb="/images/Turm_Location_Nacht.jpg">\n' +
    '            <img src="/images/Turm_Location_Nacht.jpg" />\n' +
    '        </li>\n' +
    '        <li data-thumb="/images/turm_rundgang_010.jpg">\n' +
    '            <img src="/images/turm_rundgang_010.jpg" />\n' +
    '        </li>\n' +
    '        <li data-thumb="/images/turm_rundgang_008.jpg">\n' +
    '            <img src="/images/turm_rundgang_008.jpg" />\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</div>\n' +
    '-->');
}]);
})();

(function(module) {
try {
  module = angular.module('turm-templates-homepage');
} catch (e) {
  module = angular.module('turm-templates-homepage', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/homepage/home.tpl.html',
    '<section ui-view="list" class="row tile-list-container" style="width:100%; margin:0; background-color: #393536; position: fixed;"></section>\n' +
    '<section ui-view="content" class="row"\n' +
    '         style="height:100%; width:100%; margin:0; background-color: #393536; padding-top: 19em;"></section>');
}]);
})();

(function(module) {
try {
  module = angular.module('turm-templates-homepage');
} catch (e) {
  module = angular.module('turm-templates-homepage', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/homepage/impressum.tpl.html',
    '<div class="imp-container imp-col">\n' +
    '    <div class="imp-placeholder"></div>\n' +
    '    <div class="imp-content imp-col">\n' +
    '        <div class="imp-head imp-col">\n' +
    '            <span>TURM EVENT GMBH</span>\n' +
    '            <span>FRIEDEMANN-BACH-PLATZ 5</span>\n' +
    '            <span>06108 HALLE</span>\n' +
    '        </div>\n' +
    '        <div class="imp-row imp-content">\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span>GESCHÄFTSFÜHRER:</span>\n' +
    '                <span><b>Ulf-Elmar Böttcher</b></span>\n' +
    '            </p>\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span>TEL.:</span>\n' +
    '                <span><b>0345/56642395</b></span>\n' +
    '                <span>E-Mail:</span>\n' +
    '                <span><b>info@turm-halle.de</b></span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div class="imp-row imp-content">\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span>POSTADRESSE:</span>\n' +
    '                <span><b>Postfach 110514</b></span>\n' +
    '                <span><b>06019 Halle</b></span>\n' +
    '            </p>\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span>ZUSTÄNDIGES REGISTERGERICHT:</span>\n' +
    '                <span><b>Amtsgericht Stendal</b></span>\n' +
    '                <span><b>HRB 215418</b></span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div class="imp-row imp-content">\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span>PROGRAMMIERUNG:</span>\n' +
    '                <span><b>Kai Raschke</b></span>\n' +
    '                <span><b><a href="https://kaju.it">www.kaju.it</a></b></span>\n' +
    '            </p>\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span>DESIGN:</span>\n' +
    '                <span><b>Victor-Alexander Mahn</b></span>\n' +
    '                <span><b><a href="http://vam.digital">www.vam.digital</a></b></span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div class="imp-row imp-content">\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span><b><a href="/events/calendar" target="_blank">Veranstaltungskalender (ics)</a></b></span>\n' +
    '            </p>\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span><b><a href="https://personal.turm.club">Personalverwaltung</a></b></span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div class="imp-row imp-content">\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span><b><a onclick="javascript:window.location = \'/manager\'"></a></b></span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div class="imp-row imp-content">\n' +
    '            <p class="imp-col imp-content">\n' +
    '                <span><b><a href="/datenschutzerklärung.html" target="_blank">Datenschutzerklärung</a></b></span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="imp-placeholder"></div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('turm-templates-homepage');
} catch (e) {
  module = angular.module('turm-templates-homepage', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/homepage/rent.tpl.html',
    '<div class="flex-col rent-container">\n' +
    '    <div class="rent-placeholder-half"></div>\n' +
    '    <div class="flex-col flex rent-content">\n' +
    '        <div class="rent-header">\n' +
    '            <span class="rent-head">EINMIETUNGSANFRAGE</span>\n' +
    '        </div>\n' +
    '        <ng-form class="rent-row flex rent-form" ng-submit="submit()">\n' +
    '            <div class="flex-col flex">\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="rentFirstname">VORNAME</label>\n' +
    '                    <input type="text" id="rentFirstname" ng-model="rent.firstname" maxlength="200">\n' +
    '                </div>\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="rentLastname">NACHNAME</label>\n' +
    '                    <input type="text" id="rentLastname" ng-model="rent.lastname" maxlength="200">\n' +
    '                </div>\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="rentPhone">TELEFON</label>\n' +
    '                    <input type="text" id="rentPhone" ng-model="rent.tel" maxlength="200">\n' +
    '                </div>\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="rentMail">EMAIL</label>\n' +
    '                    <input type="text" id="rentMail" ng-model="rent.mail" maxlength="200">\n' +
    '                </div>\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="rentMail">VERANSTALTUNGSART</label>\n' +
    '                    <select class="vam-dropdown" style="width: 100%;" ng-model="rent.eventtype">\n' +
    '                        <option>Disco</option>\n' +
    '                        <option>Firmenfeier</option>\n' +
    '                        <option>Sonstiges</option>\n' +
    '                    </select>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="flex-col flex">\n' +
    '                <div class="flex-row flex rent-location">\n' +
    '                    <div style="margin-right: 0.5em;">\n' +
    '                        <span>TANZSAAL</span>\n' +
    '                        <div class="rent-img-container">\n' +
    '                            <div class="left-upper">\n' +
    '\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="checkbox">\n' +
    '                            <input type="checkbox" id="left-upper" name="left-upper" ng-model="rent.tanzsaal">\n' +
    '                            <label for="left-upper"></label>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div style="margin-right: 0.5em;">\n' +
    '                        <span>WEINABTEIL</span>\n' +
    '                        <div class="rent-img-container left-lower">\n' +
    '\n' +
    '                        </div>\n' +
    '                        <div class="checkbox">\n' +
    '                            <input type="checkbox" id="left-lower" name="left-lower" ng-model="rent.weinabteil">\n' +
    '                            <label for="left-lower"></label>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="flex-row flex rent-location">\n' +
    '                    <div style="margin-left: 0.5em;">\n' +
    '                        <span>BIERGARTEN</span>\n' +
    '                        <div class="rent-img-container right-upper">\n' +
    '\n' +
    '                        </div>\n' +
    '                        <div class="checkbox">\n' +
    '                            <input type="checkbox" id="right-upper" name="right-upper" ng-model="rent.biergarten">\n' +
    '                            <label for="right-upper"></label>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div style="margin-left: 0.5em;">\n' +
    '                        <span>RONDELL</span>\n' +
    '                        <div class="rent-img-container right-lower">\n' +
    '\n' +
    '                        </div>\n' +
    '                        <div class="checkbox">\n' +
    '                            <input type="checkbox" id="right-lower" name="right-lower" ng-model="rent.rondell">\n' +
    '                            <label for="right-lower"></label>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="flex-col flex">\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <label for="rentSchedule">TERMINVORSTELLUNG</label>\n' +
    '                    <input type="text" id="rentSchedule" style="width: 100%;" ng-model="rent.date" maxlength="200">\n' +
    '                </div>\n' +
    '                <div class="flex-col flex">\n' +
    '                    <label for="rentNotes" style="flex:0 1 auto;">BEMERKUNG</label>\n' +
    '                    <textarea type="text" id="rentNotes" style="flex:1; resize: none;" ng-model="rent.note" maxlength="999"></textarea>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <script src="https://www.google.com/recaptcha/api.js" async defer></script>\n' +
    '                    <div class="g-recaptcha" data-sitekey="6LeHORoTAAAAAOOmxv5qR5NQuv41AnuOEqvmt8Fj"></div>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto">\n' +
    '                    <button type="button" ng-click="submit()" class="btn-block vam-button" style="text-align: left;margin-bottom: 2em;">Abschicken</button>\n' +
    '                </div>\n' +
    '                <div class="flex-col flex-s-auto" ng-show="rent.error">\n' +
    '                    <span>{{rent.errorMessage}}</span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </ng-form>\n' +
    '    </div>\n' +
    '    <div class="rent-placeholder"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('turm-templates-homepage');
} catch (e) {
  module = angular.module('turm-templates-homepage', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/homepage/tileList.tpl.html',
    '<div style="height: 100%;">\n' +
    '    <div class="tile-list">\n' +
    '        <style>\n' +
    '            .tile-list{\n' +
    '                color: white;\n' +
    '                height: 17em;\n' +
    '            }\n' +
    '            .tile{\n' +
    '                width: 12.5em;\n' +
    '                cursor: pointer;\n' +
    '\n' +
    '                -webkit-touch-callout: none;\n' +
    '                -webkit-user-select: none;\n' +
    '                -khtml-user-select: none;\n' +
    '                -moz-user-select: none;\n' +
    '                -ms-user-select: none;\n' +
    '                user-select: none;\n' +
    '\n' +
    '                 min-width: 12.5em;\n' +
    '                position: relative;\n' +
    '                flex: 0 0 auto;\n' +
    '            }\n' +
    '            .tile-active{\n' +
    '                background-color: white;\n' +
    '                color: black;\n' +
    '            }\n' +
    '\n' +
    '            .tile-older img{\n' +
    '                display: none;\n' +
    '            }\n' +
    '\n' +
    '            .tile-older .divider{\n' +
    '                display: none;\n' +
    '            }\n' +
    '\n' +
    '            .tile-older .tile-image-container span{\n' +
    '                display: block!important;\n' +
    '                height: 100%;\n' +
    '                text-align: center;\n' +
    '                margin-top: auto;\n' +
    '                margin-bottom: auto;\n' +
    '                font-size: 11em;\n' +
    '            }\n' +
    '\n' +
    '            .tile-history .eventDate{\n' +
    '                display: none;\n' +
    '            }\n' +
    '\n' +
    '            .tile-image-container{\n' +
    '                position: absolute;\n' +
    '                left: 0;\n' +
    '                top: 0;\n' +
    '                width: 100%;\n' +
    '                height: 100%;\n' +
    '                z-index: 10;\n' +
    '            }\n' +
    '            .tile-image-container>.overlay{\n' +
    '                z-index: 10;\n' +
    '\n' +
    '                position: absolute;\n' +
    '                left: 0;\n' +
    '                top: 0;\n' +
    '                width: 100%; height: auto;\n' +
    '            }\n' +
    '            .tile-image-container>.cover{\n' +
    '                z-index: 9;\n' +
    '\n' +
    '                position: absolute;\n' +
    '                height: 50%;\n' +
    '                top: 1.5em;\n' +
    '                left: 0;\n' +
    '                right: 0;\n' +
    '                margin-left: auto;\n' +
    '                margin-right: auto;\n' +
    '            }\n' +
    '            .mCSB_container{\n' +
    '                height: 100%;\n' +
    '            }\n' +
    '\n' +
    '            .scrollContainer{\n' +
    '                width: 100%;\n' +
    '                position: absolute;\n' +
    '                left: 0;\n' +
    '                top: 0;\n' +
    '                height: 19em;\n' +
    '                overflow: auto;\n' +
    '            }\n' +
    '\n' +
    '\n' +
    '/*            .repeat-animation.ng-enter {\n' +
    '                animation-name: comeIn;\n' +
    '                animation-duration: 0.75s;\n' +
    '                animation-iteration-count: 1;\n' +
    '                animation-timing-function: ease-in;\n' +
    '                -webkit-animation-fill-mode: forwards; /!* Chrome, Safari, Opera *!/\n' +
    '                animation-fill-mode: forwards;\n' +
    '                /!*-webkit-animation-delay: 0.75s; /!* Chrome, Safari, Opera *!/\n' +
    '                animation-delay: 0.75s;*!/\n' +
    '            }\n' +
    '            .repeat-animation.ng-enter .overlay{\n' +
    '                height: 100%;\n' +
    '            }*/\n' +
    '\n' +
    '            @keyframes comeIn {\n' +
    '                0% {\n' +
    '                    width:0;\n' +
    '                    min-width: 0;\n' +
    '                    opacity: 0;\n' +
    '                }\n' +
    '                80% {\n' +
    '                    opacity: 0;\n' +
    '                }\n' +
    '                100% {\n' +
    '                    width:12.5em;\n' +
    '                    min-width: 12.5em;\n' +
    '                    opacity: 1;\n' +
    '                }\n' +
    '            }\n' +
    '            @-webkit-keyframes comeIn {\n' +
    '                0% {\n' +
    '                    width:0;\n' +
    '                    min-width: 0;\n' +
    '                    opacity: 0;\n' +
    '                }\n' +
    '                80% {\n' +
    '                    opacity: 0;\n' +
    '                }\n' +
    '                100% {\n' +
    '                    width:12.5em;\n' +
    '                    min-width: 12.5em;\n' +
    '                    opacity: 1;\n' +
    '                }\n' +
    '            }\n' +
    '            @-moz-keyframes comeIn {\n' +
    '                from { width:0; opacity: 0; }\n' +
    '                to { width:12.5em; opacity: 1; }\n' +
    '            }\n' +
    '            @-o-keyframes comeIn {\n' +
    '                from { width:0; opacity: 0; }\n' +
    '                to { width:12.5em; opacity: 1; }\n' +
    '            }\n' +
    '\n' +
    '            .repeat-animation.ng-leave {\n' +
    '                -webkit-animation:0.75s goOut;\n' +
    '                -moz-animation:0.75s goOut;\n' +
    '                -o-animation:0.75s goOut;\n' +
    '                animation:0.75s goOut;\n' +
    '            }\n' +
    '\n' +
    '            .repeat-animation.ng-leave .overlay{\n' +
    '                height: 100%;\n' +
    '            }\n' +
    '\n' +
    '            @keyframes goOut {\n' +
    '                0% {\n' +
    '                    width:12.5em;\n' +
    '                    min-width: 12.5em;\n' +
    '                    opacity: 1;\n' +
    '                    /*left:0;*/\n' +
    '                }\n' +
    '                20% {\n' +
    '                    opacity: 0;\n' +
    '                }\n' +
    '                100% {\n' +
    '                    width:0em;\n' +
    '                    min-width: 0;\n' +
    '                    opacity: 0;\n' +
    '                    /*left:-100px;*/\n' +
    '                }\n' +
    '            }\n' +
    '            @-webkit-keyframes goOut {\n' +
    '                0% {\n' +
    '                    width:12.5em;\n' +
    '                    min-width: 12.5em;\n' +
    '                    opacity: 1;\n' +
    '                    /*left:0;*/\n' +
    '                }\n' +
    '                20% {\n' +
    '                    opacity: 0;\n' +
    '                }\n' +
    '                100% {\n' +
    '                    width:0em;\n' +
    '                    min-width: 0;\n' +
    '                    opacity: 0;\n' +
    '                    /*left:-100px;*/\n' +
    '                }\n' +
    '            }\n' +
    '\n' +
    '            .choose-tile-animation-in{\n' +
    '                 opacity: 0;\n' +
    '                -webkit-animation: opac-in;\n' +
    '                 animation-name: opac-in;\n' +
    '                -webkit-animation-duration: 0.5s;\n' +
    '                 animation-duration: 0.5s;\n' +
    '                -webkit-animation-iteration-count: 1;\n' +
    '                 animation-iteration-count: 1;\n' +
    '                 -webkit-animation-fill-mode: forwards; /* Chrome, Safari, Opera */\n' +
    '                 animation-fill-mode: forwards;\n' +
    '                 -webkit-animation-timing-function: ease; /* Chrome, Safari, Opera */\n' +
    '                 animation-timing-function: ease;\n' +
    '             }\n' +
    '\n' +
    '            @keyframes opac-in {\n' +
    '                from {opacity: 0;}\n' +
    '                to {opacity: 1;}\n' +
    '            }\n' +
    '            @-webkit-keyframes opac-in {\n' +
    '                from {opacity: 0;}\n' +
    '                to {opacity: 1;}\n' +
    '            }\n' +
    '\n' +
    '            .overlay{\n' +
    '                z-index: 10;\n' +
    '            }\n' +
    '            .overlay-white{\n' +
    '                z-index: 11;\n' +
    '                position: absolute;\n' +
    '                left: 0;\n' +
    '                top: 0;\n' +
    '                width: 100%;\n' +
    '                height: auto;\n' +
    '            }\n' +
    '        </style>\n' +
    '        <div class="scrollContainer" ng-scrollbars ng-scrollbars-config="config" style="box-shadow: 0px 0px 20px black;">\n' +
    '            <div style="height: 17em; display: flex; flex-direction: row;" >\n' +
    '                <div class="repeat-animation tile" ng-repeat="tile in allFilteredTiles" ng-click="chooseTileClick(tile, $index)"\n' +
    '                     ng-class="{\'tile-active\': $index == active, \'tile-older\': tile.type === \'mover\', \'tile-history\': tile.eventType === \'history\'}" style="height: 100%; float: left;">\n' +
    '                    <div class="tile-image-container">\n' +
    '                        <span style="display: none;">&lt;</span>\n' +
    '                        <img ng-if="$index%3 == 0" src="images/Karte_Duenner_01.svg" class="overlay">\n' +
    '                        <img ng-if="$index%3 == 1" src="images/Karte_Duenner_02.svg" class="overlay">\n' +
    '                        <img ng-if="$index%3 == 2" src="images/Karte_Duenner_03.svg" class="overlay">\n' +
    '                        <img ng-show="$index == active" src="images/Karte_Duenner_Aktiv.svg" class="overlay-white"\n' +
    '                             ng-class="{\'choose-tile-animation-in\': $index == active}">\n' +
    '                        <img ng-src="{{tile.eventCover.src}}" class="cover">\n' +
    '                        <img ng-if="!tile.eventCover" src="images/Karten_Platzhalter01.svg">\n' +
    '                    </div>\n' +
    '                    <div class="eventTitle" style="position: absolute; top: 10em; width: 100%; z-index: 11;">\n' +
    '                        <span style="font-size: 1.05em; font-weight: 600;">{{::tile.eventTitle}}</span>\n' +
    '                    </div>\n' +
    '                    <div style="padding-right: 2em; padding-left:1em; position: absolute; width: 100%; bottom: 0.5em; z-index: 11;">\n' +
    '                        <div class="eventDate">\n' +
    '                            <span style="font-size: 1.05em; font-weight: 300;">{{::tile.eventDate | date:\'EEE d. MMM yyyy\'}}</span>\n' +
    '                        </div>\n' +
    '                        <div class="divider"></div>\n' +
    '                        <div class="eventType">\n' +
    '                            <span style="font-size: 1.05em;" tile-type="{{::tile.eventType}}"></span>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();
